import React from 'react';
import { graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-react-intl';
import { parseLocale } from '../locale';
import { DonateToOurPartnersQuery } from '../../graphql-types';
import { DonateToOurPartnersAllTemplate } from '../templates/donate-to-our-partners.template';

import SEO from '../components/seo';
import { mapQueryToDonateToOurPartnersProps } from '../data-mappers/donate-to-our-partners.mapper';
import { useBreadcrumbs } from '../hooks/useBreadcrumbs';

const DonateToOurPartners: React.FC<{ data: DonateToOurPartnersQuery }> = ({ data }) => {
  const { cms } = data;

  const intl = useIntl();
  const locale = parseLocale(intl.locale);

  const props = mapQueryToDonateToOurPartnersProps(cms, locale);

  useBreadcrumbs({
    pageTitle: cms.donateToOurPartner?.title ?? '',
    contentCategory: intl.formatMessage({ id: 'kering_foundation' }),
  });

  return (
    <>
      <SEO
        lang={locale}
        title={cms.donateToOurPartner?.title ?? ''}
        description={cms.donateToOurPartner?.metas?.description ?? ''}
      />
      <DonateToOurPartnersAllTemplate {...props} />
    </>
  );
};

export default DonateToOurPartners;

export const query = graphql`
  query DonateToOurPartners($language: String) {
    cms {
      donateToOurPartner(locale: $language) {
        title
        header {
          ...DonatePartnersHeader
        }
        metas {
          description
        }
      }
      categories(sort: "created_at:desc", locale: $language, where: { display_on_donate_page: true }) {
        ...ProgramCategory
        programCount
        programs(limit: 5, sort: "title:asc") {
          ...Program
          donation_page_thumbnail {
            ...ProgramThumbnail
          }
        }
      }
    }
  }

  fragment DonatePartnersHeader on cms_ComponentDonatePartnersHeader {
    order
    markdown {
      ...AlignedMarkdown
    }
    media {
      ...CommonMediaField
      staticFile {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
    youtube_embed_id
  }
`;
